interface IProps {
  isOpen: boolean;
}
const Component = (props: IProps) => {
  const { isOpen } = props;
  return (
    <div
      className={`transition-all ease-in-out duration-800 relative  container mx-auto grid grid-cols-1 lg:grid-cols-3 items-start gap-x-20 ${
        isOpen ? 'max-h-fit my-20 mb-32' : 'max-h-0 overflow-hidden '
      }`}>
      <div className="w-auto flex flex-wrap items-start justify-start">
        <div className=" flex flex-wrap items-center">
          <h2 className="text-h5 mb-5 font-bold uppercase">ACCUMULATION</h2>
        </div>

        <p className="text-md xl:text-lg mb-3">
          We maintain our exposure to the market by rebalancing our positions after market
          deleveraging, and actively monitor volatility to inform our decision-making during bullish
          market conditions.
        </p>
        <div className="bg-lime w-32 absolute -bottom-10 h-2"></div>
      </div>
      <div className="w-auto flex flex-wrap items-start justify-start">
        <div className=" flex flex-wrap items-center">
          <h2 className="text-h5 mb-5 font-bold uppercase">Concentrated Liquidity Arbitrage</h2>
        </div>

        <p className="text-md xl:text-lg mb-3">
          Our market making strategy involves arbitraging the bid/ask spread by using ensemble
          learning, classification, and regression algorithms (machine learning) to reduce order
          book noise.
        </p>
      </div>
      <div className="w-auto flex flex-wrap items-start justify-start">
        <div className="flex flex-wrap items-center">
          <h2 className="w-full text-h5 mb-5 font-bold uppercase">Market Making</h2>
        </div>

        <p className="text-md xl:text-lg mb-3">
          As experts in various blockchain ecosystems such as Ethereum, Optimistic, and Arbitrum, we
          specialize in identifying and capturing maximal extractable value (MEV) opportunities. By
          conducting rigorous analysis and optimization of our MEV strategy, we aim to deliver
          exceptional returns to our investors.
        </p>
      </div>
    </div>
  );
};
export default Component;
