import React from 'react';
import bgVideo from '../../../assets/bgVideo.mp4';
const Component = () => {
  const first_text = 'THE HIGHEST VISIBILITY';
  const second_text = 'OF OPPORTUNITIES';
  const third_text = 'IN THE MARKET';
  const [text1, setText1] = React.useState('');
  const [text2, setText2] = React.useState('');
  const [text3, setText3] = React.useState('');
  const [finished, setFinished] = React.useState({
    first: false,
    second: false,
    third: false,
  });
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setText1(first_text.slice(0, text1.length + 1));
    }, 100);
    const timeoutFinish = setTimeout(() => {
      setFinished({ ...finished, first: true });
    }, 1000);
    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutFinish);
    };
  }, [text1]);

  React.useEffect(() => {
    if (finished.first) {
      const timeout = setTimeout(() => {
        setText2(second_text.slice(0, text2.length + 1));
      }, 150);
      const timeoutFinish = setTimeout(() => {
        setFinished({ ...finished, second: true });
      }, 1000);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeoutFinish);
      };
    }
  }, [finished.first, text2]);

  React.useEffect(() => {
    if (finished.second) {
      const timeout = setTimeout(() => {
        setText3(third_text.slice(0, text3.length + 1));
      }, 150);
      const timeoutFinish = setTimeout(() => {
        setFinished({ ...finished, third: true });
      }, 1000);
      return () => {
        clearTimeout(timeout);
        clearTimeout(timeoutFinish);
      };
    }
  }, [finished.second, text3]);

  return (
    <div className="w-full flex flex-wrap container mx-auto items-center py-40 relative min-h-screen">
      <video autoPlay playsInline muted loop className="video">
        <source src={bgVideo} type="video/mp4" />
      </video>
      <h1 className="absolute lg:relative text-h4 lg:text-h2 lg:text-h1 font-semibold whitespace-pre-line	text-lime">
        _
      </h1>
      <div className="w-full relative pl-2 md:pl-4 lg:pl-0 lg:w-3/4 h-1/2">
        <div className="relative">
          <h1
            data-text={text1}
            className={`w-full text-h4 md:text-h2 lg:text-h1 font-semibold px-2 whitespace-pre-line  ${
              !finished.first && text1 !== '' ? 'blinking-cursor' : text1 !== '' && 'glitch'
            }`}>
            {text1}
          </h1>
        </div>
        <div className="relative">
          <h1
            data-text={text2}
            className={`w-full text-h4 md:text-h2 lg:text-h1 font-semibold px-2 whitespace-pre-line ${
              !finished.second && text2 !== '' ? 'blinking-cursor' : text2 !== '' && 'glitch'
            }`}>
            {text2}
          </h1>
        </div>
        <div className="relative">
          <h1
            data-text={text3}
            className={`w-full text-h4 md:text-h2 lg:text-h1 font-semibold px-2 whitespace-pre-line ${
              text3 !== '' && 'blinking-cursor glitch'
            }`}>
            {text3}
            {finished.third && (
              <span className="text-h4 md:text-h2 lg:text-h1 px-5 font-semibold whitespace-pre-line text-lime">
                .
              </span>
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};
export default Component;
