import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { User } from '../../models/User';
export interface loginPayload {
  user: User;
  token: string;
}
export interface registerPayload {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  referral_code: string;
}
interface profilePayload {
  email: string;
  old_password: string;
  new_password: string;
  new_password_confirm: string;
}
export const loginQuery = createAsyncThunk(
  'auth/login',
  async (payload: { email: string; password: string }) => {
    let query;
    try {
      query = await axios.post(`/api/auth/login`, payload);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const registerQuery = createAsyncThunk('auth/register', async (payload: registerPayload) => {
  let query;
  try {
    query = await axios.post(`/api/auth/register`, payload);
    return query;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const editProfileQuery = createAsyncThunk(
  'auth/editProfile',
  async (payload: profilePayload) => {
    let query;
    try {
      query = await axios.patch(`/api/user/profile`, {
        email: payload.email,
        old_password: payload.old_password,
        new_password: payload.new_password === '' ? undefined : payload.new_password,
        new_password_confirm:
          payload.new_password_confirm === '' ? undefined : payload.new_password_confirm,
      });
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const sendCodeQuery = createAsyncThunk('auth/sendCode', async (email: string) => {
  let query;
  try {
    query = await axios.post(`/api/auth/send-code`, {
      email,
    });
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const recoverAccountQuery = createAsyncThunk(
  'auth/recoverAccount',
  async (payload: { [key: string]: string }) => {
    const { email, code, new_password, new_password_confirm } = payload;
    let query;
    try {
      query = await axios.post(`/api/auth/recover-account`, {
        email,
        code,
        new_password,
        new_password_confirm,
      });
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
