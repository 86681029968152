import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface IProps {
  data: { date: string; fairshare: number; amount: number; since_inception: number }[];
  fairshare: number;
}
const Component = (props: IProps) => {
  const { data } = props;
  const labels = data.map((d) => d.date);
  const formatedData = {
    labels,
    datasets: [
      {
        type: 'line' as const,
        label: 'Return',
        borderColor: '#CBF429',
        borderWidth: 2,
        fill: false,
        data: data.map((d) =>
          Number.isNaN(d.since_inception) || d.since_inception === -100 ? 0 : d.since_inception
        ),
        yAxisID: 'y1',
      },
      {
        type: 'bar' as const,
        label: 'NAV',
        backgroundColor: '#9788F0',
        data: data.map((d) => (d.amount ? +d.amount.toFixed(2) : d.amount)),
        borderWidth: 0,
        yAxisID: 'y',
      },
    ],
  };
  return (
    <Chart
      type="bar"
      data={formatedData}
      options={{
        responsive: true,

        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: '#fff',
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                if (context.dataset.type === 'line') return ` ${context.parsed.y.toFixed(2)} %`;
                else return ` ${context.parsed.y} USDT`;
              },
            },
          },
        },
        scales: {
          x: {
            border: {
              display: true,
            },
            grid: {
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
            },
          },
          y: {
            border: {
              display: true,
            },
            grid: {
              color: function (context) {
                if (context.tick.value > 0) {
                  return '#212020';
                } else if (context.tick.value < 0) {
                  return '#212020';
                }

                return '#212020';
              },
            },
          },

          y1: {
            type: 'linear',
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
      }}
    />
  );
};
export default Component;
