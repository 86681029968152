import { icon, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { clearStateUserData } from '../../../store/features/auth.slice';
import { Button } from '../../atoms';

const Component = (props: { isLogged: boolean; handleClickScroll: Function }) => {
  const { isLogged, handleClickScroll } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);
  return (
    <header className="bg-black lg:bg-transparent z-50 absolute top-0 left-0 py-5 w-full">
      <nav className="container mx-auto">
        <div className="flex flex-wrap items-center justify-between">
          <img src={logo} alt="Chispi" width={200} onClick={() => navigate('/')} />

          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none"
            aria-controls="navbar-default"
            aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-4 h-4"
              aria-hidden="true"
              fill="#fff"
              onClick={() => setMenuOpen(!menuOpen)}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"></path>
            </svg>
          </button>

          <div
            className={`${menuOpen ? 'w-full' : 'hidden'} w-full lg:block lg:w-auto`}
            id="navbar-default">
            <ul className="font-medium flex flex-col p-4 lg:p-0 mt-4 rounded-lg lg:flex-row lg:space-x-10 space-y-4 lg:space-y-0 lg:mt-0 md:border-0 ">
              {!isLogged ? (
                <>
                  <Button
                    handleClick={() => {
                      navigate('/login');
                    }}
                    text="Log in"
                    textProps={{ fontWeight: 'normal', direction: 'justify-start lg:justify-end' }}
                    icon={<FontAwesomeIcon icon={icon({ name: 'right-to-bracket' })} />}
                  />

                  <Button
                    handleClick={handleClickScroll}
                    text="Contact Us"
                    textProps={{ textStyle: 'uppercase', fontWeight: 'bold' }}
                  />
                </>
              ) : (
                <>
                  {user?.type !== 'admin' ? (
                    <>
                      {' '}
                      <Button
                        handleClick={() => {
                          navigate('/deposit');
                        }}
                        text="Deposit"
                        textProps={{ textStyle: 'uppercase', fontWeight: 'bold' }}
                      />
                      <Button
                        handleClick={() => {
                          navigate('/withdraw');
                        }}
                        text="Withdrawal"
                        btnProps={{ style: 'outline' }}
                        textProps={{ textStyle: 'uppercase', fontWeight: 'bold' }}
                      />
                    </>
                  ) : (
                    <Button
                      handleClick={() => {
                        navigate('/admin');
                      }}
                      text="Admin panel"
                      textProps={{ textStyle: 'uppercase', fontWeight: 'bold' }}
                    />
                  )}

                  {user && (
                    <Button
                      handleClick={() => {
                        navigate('/profile');
                      }}
                      text={user.firstName}
                      textProps={{ fontWeight: 'normal' }}
                      icon={<FontAwesomeIcon icon={solid('user')} />}
                    />
                  )}
                  <Button
                    handleClick={() => {
                      dispatch(clearStateUserData());
                    }}
                    text="Log out"
                    textProps={{ fontWeight: 'normal' }}
                    icon={<FontAwesomeIcon icon={icon({ name: 'right-to-bracket' })} />}
                  />
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Component;
