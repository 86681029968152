import faqs from '../../../data/faqs.json';
interface IProps {
  isOpen: boolean;
}
const Component = (props: IProps) => {
  const { isOpen } = props;
  return (
    <div
      className={`transition-all ease-in-out duration-800 relative container mx-auto grid grid-cols-1 lg:grid-cols-2 items-start gap-x-20 ${
        isOpen ? 'max-h-fit  my-20' : 'max-h-0 overflow-hidden'
      }`}>
      {faqs.map((faq) => (
        <div className="w-auto flex flex-wrap items-start justify-start pb-10">
          <h3 className="w-full lg:text-xl md:text-lg font-bold uppercase pb-3 border-b border-color-white">
            {faq.title}
          </h3>
          <p className="my-3 text-md xl:text-lg">{faq.text}</p>
        </div>
      ))}
    </div>
  );
};
export default Component;
