import React from 'react';
import { TitleSection } from '../../components/molecules';
import { Hero, HorizontalPills } from '../../components/organisms';
import { Layout } from '../../components/templates';
const HomePage = () => {
  const [isOpen, setIsOpen] = React.useState<string>('');
  const handleClickScroll = () => {
    const element = document.getElementById('contact');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
      setIsOpen(isOpen === 'contact' ? '' : 'contact');
    }
  };
  return (
    <Layout handleClickScroll={handleClickScroll}>
      <Hero />
      <HorizontalPills />

      <section className="my-20">
        <TitleSection
          number={'01'}
          title="The Opportunity"
          id="opportunity"
          isOpen={isOpen === 'opportunity'}
          setIsOpen={() => setIsOpen(isOpen === 'opportunity' ? '' : 'opportunity')}
        />
        <TitleSection
          number={'02'}
          title="Our fund"
          id="funds"
          isOpen={isOpen === 'funds'}
          setIsOpen={() => setIsOpen(isOpen === 'funds' ? '' : 'funds')}
        />
        <TitleSection
          number={'03'}
          title="Our tools"
          id="tools"
          isOpen={isOpen === 'tools'}
          setIsOpen={() => setIsOpen(isOpen === 'tools' ? '' : 'tools')}
        />
        <TitleSection
          number={'04'}
          title="Our strategies"
          id="strategies"
          isOpen={isOpen === 'strategies'}
          setIsOpen={() => setIsOpen(isOpen === 'strategies' ? '' : 'strategies')}
        />
        <TitleSection
          number={'05'}
          title="Faqs"
          id="faqs"
          isOpen={isOpen === 'faqs'}
          setIsOpen={() => setIsOpen(isOpen === 'faqs' ? '' : 'faqs')}
        />
        <TitleSection
          number={'06'}
          title="Contact us"
          id="contact"
          isOpen={isOpen === 'contact'}
          setIsOpen={() => setIsOpen(isOpen === 'contact' ? '' : 'contact')}
        />
      </section>
    </Layout>
  );
};
export default HomePage;
