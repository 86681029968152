import { validate } from 'crypto-address-validator-ts';

export const validateAddress = (address: string, chain: string) => {
  const TRC20 = /^T[A-Za-z1-9]{33}$/;

  switch (chain) {
    case 'TRC20':
      return TRC20.test(address);
    case 'BEP20':
      return validate(address, 'usdt', { networkType: 'prod', chainType: chain });
    case 'ETH':
      return validate(address, 'usdt', { networkType: 'prod', chainType: chain });
  }
};

export const getYearsBetween = (startDate: Date, endDate: Date = new Date()) => {
  // Get the current year
  const currentYear = endDate.getFullYear();
  // Get the start year from the start date
  const startYear = startDate.getFullYear();
  // Create an array to store the years
  const years = [];
  // Loop through the years and add them to the array
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }

  return years;
};
