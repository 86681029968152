import offChain from '../../../assets/icons/offChain.svg';
import onChain from '../../../assets/icons/onChain.svg';
import microPrice from '../../../assets/icons/microPrice.svg';
interface IProps {
  isOpen: boolean;
}
const Component = (props: IProps) => {
  const { isOpen } = props;
  return (
    <div
      className={`transition-all ease-in-out duration-800 ${
        isOpen ? 'max-h-fit my-20 ' : 'max-h-0 overflow-hidden'
      } relative container mx-auto  grid grid-cols-1 lg:grid-cols-3 items-start gap-x-20`}>
      <div className="flex flex-wrap items-start justify-start">
        <div className=" flex flex-wrap items-center mb-5">
          <img src={onChain} alt="" className="mr-2" />
          <h2 className="text-lg xl:text-h5  font-bold uppercase">On-chain</h2>
        </div>

        <p className="text-md xl:text-lg mb-3">
          At Chispi Research, we are a team of experts from diverse backgrounds, including
          actuaries, economists, physicists, and mathematicians. Our shared passion for the
          transformative potential of blockchain technology led us to build a quantitative
          cryptocurrency asset management firm that provides sophisticated investment solutions to
          investors.
        </p>
      </div>
      <div className="flex flex-wrap items-start justify-start">
        <div className="flex flex-wrap items-center mb-5">
          <img src={offChain} alt="" className="mr-2" />
          <h2 className="text-lg xl:text-h5  font-bold uppercase">Off-chain</h2>
        </div>

        <p className="text-md xl:text-lg mb-3">
          Our unique approach includes market making in both centralized and decentralized
          exchanges, while maintaining a long exposure for the new incoming cycle. We have developed
          proprietary tools that give us a real edge over market participants, allowing us to
          provide our investors with exceptional returns and mitigated risks.
        </p>
      </div>
      <div className="flex flex-wrap items-start justify-start">
        <div className="flex flex-wrap items-center mb-5">
          <img src={microPrice} alt="" className="mr-2" />
          <h2 className="w-3/4 lg:w-2/3 text-lg xl:text-h5  font-bold uppercase">
            MICRO-PRICE VOLATILITY MODEL
          </h2>
        </div>

        <p className="text-md xl:text-lg mb-3">
          Our ultimate goal is to build a thriving investment ecosystem that connects capital with
          the generational bias opportunity that the cryptocurrency market represents. We believe
          that the future of finance lies in the blockchain, and we are committed to being at the
          forefront of this transformation. Through rigorous research, analysis, and innovation, we
          strive to help investors navigate this dynamic market with confidence and ease.
        </p>
      </div>
    </div>
  );
};
export default Component;
