import React from 'react';
interface IForm {
  name: string;
  email: string;
  organization: string;
  message: string;
}
interface IProps {
  isOpen: boolean;
}
const Component = (props: IProps) => {
  const { isOpen } = props;
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
  };
  const [form, setForm] = React.useState<IForm>({
    name: '',
    email: '',
    organization: '',
    message: '',
  });
  return (
    <div
      className={`transition-all ease-in-out duration-800 ${
        isOpen ? 'max-h-fit  my-20' : 'max-h-0 overflow-hidden'
      } relative  container mx-auto flex flex-wrap items-start gap-x-20`}>
      <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)} id="contact">
        <div className="mb-5">
          <label className="text-sm">Name</label>
          <input
            type={'text'}
            className="w-full"
            onChange={(e) => setForm({ ...form, name: e.target.value })}
          />
        </div>
        <div className="mb-5">
          <label className="text-sm">Email</label>
          <input
            type={'text'}
            className="w-full"
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
        </div>
        <div className="mb-5">
          <label className="text-sm">Organization</label>
          <input
            type={'text'}
            className="w-full"
            onChange={(e) => setForm({ ...form, organization: e.target.value })}
          />
        </div>
        <div className="mb-5">
          <label className="text-sm">Your message here...</label>
          <textarea
            className="w-full"
            onChange={(e) => setForm({ ...form, message: e.target.value })}></textarea>
        </div>
        <div className="w-full flex flex-wrap justify-end">
          <input type="submit" value="Send" className="bg-violet" />
        </div>
      </form>
    </div>
  );
};
export default Component;
