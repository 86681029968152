import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createUserQuery } from '../../../store/api/admin.api';
interface IForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  referral_code: string;
  phone: string;
  type: string;
  status: string;
}
const Component = () => {
  const { isFetching } = useAppSelector((state) => state.admin);
  const dispatch = useAppDispatch();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<IForm>({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    referral_code: '',
    phone: '',
    status: 'ACCEPTED',
    type: '',
  });
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(createUserQuery(form));
  };
  React.useEffect(() => {
    const { email, password, firstName, lastName, referral_code, phone } = form;
    if (
      email !== '' &&
      password !== '' &&
      firstName !== '' &&
      lastName !== '' &&
      referral_code !== '' &&
      phone !== ''
    )
      setIsValid(true);
    else setIsValid(false);
  }, [form]);
  return (
    <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-5">
        <label className="text-sm">Firstname</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, firstName: e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Lastname</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, lastName: e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Phone</label>
        <input
          type={'tel'}
          className="w-full"
          onChange={(e) => setForm({ ...form, phone: e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Email</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, email: e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Rol</label>
        <select className="w-full" onChange={(e) => setForm({ ...form, type: e.target.value })}>
          <option key={''} value="">
            Select one
          </option>
          <option key={'admin'} value="admin">
            Admin
          </option>
          <option key={'comercial'} value="comercial">
            Comercial
          </option>
          <option key={'user'} value="user">
            Usuario
          </option>
        </select>
      </div>
      <div className="mb-5">
        <label className="text-sm">Password</label>
        <input
          type={'password'}
          className="w-full"
          onChange={(e) => setForm({ ...form, password: e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Referral Code</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, referral_code: e.target.value })}
        />
      </div>

      <div className="w-full flex flex-wrap items-center justify-end">
        <input
          type="submit"
          value="Create"
          className={isValid ? 'bg-violet' : 'bg-gray-400'}
          disabled={!isValid || isFetching}
        />
      </div>
    </form>
  );
};
export default Component;
