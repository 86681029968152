interface IButton {
  handleClick: Function;
  text: string;
  textProps?: ITextProps;
  icon?: React.ReactElement;
  btnProps?: IBtnProps;
}
interface IBtnProps {
  style?: 'outline' | 'default';
  textStyle?: 'normal' | 'uppercase';
}
interface ITextProps {
  fontWeight?: 'bold' | 'semibold' | 'normal';
  textStyle?: 'normal' | 'uppercase';
  direction?: string;
}
const Component = (props: IButton) => {
  const { text, handleClick, textProps, btnProps, icon } = props;
  return icon ? (
    <div
      className={`w-36 flex bg-transparent rounded-md text-white py-1 font-${textProps?.fontWeight} ${textProps?.textStyle} ${textProps?.direction} cursor-pointer`}
      onClick={() => handleClick()}>
      {icon && <span className="pr-2">{icon}</span>}
      {text}
    </div>
  ) : (
    <div
      className={
        btnProps?.style === 'outline'
          ? `w-36 flex justify-center bg-transparent border border-violet rounded-md text-white py-1 font-${textProps?.fontWeight} ${textProps?.textStyle}  cursor-pointer`
          : `w-36 flex justify-center bg-violet border border-violet rounded-md text-white py-1 font-${textProps?.fontWeight} ${textProps?.textStyle}  cursor-pointer`
      }
      onClick={() => handleClick()}>
      <span>{text}</span>
    </div>
  );
};
export default Component;
