import React from 'react';
import { useAppDispatch } from '../../../hooks';
import { createFairshareQuery, fairsharesQuery, usersQuery } from '../../../store/api/admin.api';
import moment from 'moment';

const Component = () => {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = React.useState<boolean>(true);
  const [form, setForm] = React.useState({
    amount: 0,
    date: moment(new Date()).format('YYYY-MM-DD'),
    comission: 0,
  });
  React.useEffect(() => {
    form.amount !== 0 ? setDisabled(false) : setDisabled(true);
  }, [form]);
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    dispatch(createFairshareQuery(form)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(fairsharesQuery());
        dispatch(usersQuery('all'));
      }
    });
  };
  return (
    <form className="flex flex-wrap w-3/4 mx-auto justify-end" onSubmit={(e) => handleSubmit(e)}>
      <h3 className="w-full text-center text-2xl my-5">Upload a new Fairshare</h3>
      <label className="w-full mb-1 font-semibold">Date</label>
      <input
        type="date"
        className="w-full mb-4"
        placeholder="Fecha"
        step="any"
        onChange={(e) => setForm({ ...form, date: moment(e.target.value).format('YYYY-MM-DD') })}
      />
      <label className="w-full mb-1 font-semibold">Value</label>
      <input
        type="number"
        className="w-full mb-4"
        placeholder="Fairshare value"
        step="any"
        onChange={(e) => setForm({ ...form, amount: +e.target.value })}
      />
      <label className="w-full mb-1 font-semibold">Comission</label>
      <input
        type="number"
        className="w-full mb-4"
        placeholder="Comission"
        value={form.comission}
        step="any"
        onChange={(e) => setForm({ ...form, comission: +e.target.value })}
      />
      <input type="submit" value="Upload" disabled={disabled} />
    </form>
  );
};
export default Component;
