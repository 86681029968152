export enum ChainTypes {
  TRC20 = 'TRC20',
  BEP20 = 'BEP20',
  ETH = 'ETH',
}

export const ChainTypesLabels = {
  [ChainTypes.TRC20]: 'Tron',
  [ChainTypes.BEP20]: 'Binance',
  [ChainTypes.ETH]: 'Ethereum',
};
