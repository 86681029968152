import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../models/User';
import { errorToast, successToast } from '../../utils/toast';
import {
  loginPayload,
  loginQuery,
  recoverAccountQuery,
  registerQuery,
  sendCodeQuery,
} from '../api/auth.api';
import type { RootState } from '../store';
import { editProfileQuery } from '../api/auth.api';
interface AuthState {
  user: User | null;
  token: string | null;
  value: number;
  status: string;
}

const initialState: AuthState = {
  user: window.localStorage
    ? JSON.parse(window.localStorage.getItem('Chispi_user') as string)
    : null,
  token: window.localStorage ? window.localStorage.getItem('Chispi_token') : null,
  value: 0,
  status: 'idle',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    incrementByAmount: (state, action: PayloadAction<number>) => {
      state.value += action.payload;
    },
    clearStateUserData: (state) => {
      state.user = null;
      state.token = null;
      window.localStorage.clear();
    },
    setInitialStateUser: (state) => {
      state.user = null;
      state.token = null;
    },
  },
  extraReducers: {
    [loginQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [loginQuery.fulfilled.toString()]: (state, action: PayloadAction<loginPayload>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      const user = action.payload.user;
      localStorage.setItem('Chispi_user', JSON.stringify(user));
      localStorage.setItem('Chispi_token', action.payload.token);
      window.dispatchEvent(new Event('storage'));
      successToast(`Bienvenido ${user.firstName} ${user.lastName}`);
      state.status = 'completed';
    },
    [loginQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [registerQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [registerQuery.fulfilled.toString()]: (state) => {
      state.status = 'completed';
    },
    [registerQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [editProfileQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [editProfileQuery.fulfilled.toString()]: (state, action) => {
      localStorage.setItem('Chispi_user', JSON.stringify(action.payload.user));
      state.user = action.payload.user;
      successToast(`Profile edited succesfully`);
      state.status = 'completed';
    },
    [editProfileQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [sendCodeQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [sendCodeQuery.fulfilled.toString()]: (state, action) => {
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [sendCodeQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
    [recoverAccountQuery.pending.toString()]: (state) => {
      state.status = 'pending';
    },
    [recoverAccountQuery.fulfilled.toString()]: (state, action) => {
      successToast(action.payload.message);
      state.status = 'completed';
    },
    [recoverAccountQuery.rejected.toString()]: (state, action) => {
      state.status = 'rejected';
      errorToast(action.error.message);
    },
  },
});

export const { incrementByAmount, clearStateUserData } = authSlice.actions;

export const selectCount = (state: RootState) => state.auth.value;

export default authSlice.reducer;
