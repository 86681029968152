import moment from 'moment';
import { Transaction } from '../../../models/Transaction';
interface IProps {
  transactions: Transaction[];
}
const Component = (props: IProps) => {
  const { transactions } = props;
  return (
    <div className="w-full overflow-x max-h-[500px] overflow-y-scroll rounded-md border border-darkgray rounded-b-md">
      <div className="w-full bg-darkgray rounded-t-md sticky top-0 left-0">
        <h2 className="font-semibold uppercase text-center py-2 lg:text-xl md:text-lg">
          Deposits / Withdraws
        </h2>
      </div>
      <div className="overflow-x">
        <table className="table-auto overflow-scroll w-full">
          <thead>
            <tr>
              <th className="text-sm md:text-md py-2 text-center">Date</th>
              <th className="text-sm md:text-md py-2 text-center">Type</th>
              <th className="text-sm md:text-md py-2 text-center">USD</th>
              <th className="text-sm md:text-md py-2 text-center">Fairshares</th>
              <th className="text-sm md:text-md py-2 text-center">Address</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((t, i) => (
              <tr key={i}>
                <td className="text-xs md:text-sm py-2 text-center">
                  {moment(t.date).format('ll')}
                </td>
                <td className="text-xs md:text-sm py-2 text-center">{t.type}</td>
                <td className="text-xs md:text-sm py-2 text-center">${t.amount}</td>
                <td className="text-xs md:text-sm py-2 text-center">{t.fairshare}</td>
                <td className="text-xs md:text-sm py-2 text-center">{t.address}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Component;
