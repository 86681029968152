import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
export interface createUserPayload {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  password: string;
  referral_code: string;
  type: string;
  status: string;
}
export interface createTransactionPayload {
  user: string;
  address: string;
  chain: string;
  amount: number;
  fairshare: number;
  comission: number;
  type: string;
  date: string;
}
export const usersQuery = createAsyncThunk('admin/users', async (status?: string) => {
  let query;
  try {
    query = await axios.get(`/api/user?status=${status}`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const walletsQuery = createAsyncThunk('admin/wallets', async () => {
  let query;
  try {
    query = await axios.get(`/api/wallet`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const transactionsQuery = createAsyncThunk('admin/transactions', async () => {
  let query;
  try {
    query = await axios.get(`/api/transaction`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const transactionsByIdQuery = createAsyncThunk(
  'admin/transaction/id',
  async (id: string) => {
    let query;
    try {
      query = await axios.get(`/api/transaction/${id}`);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const transactionsRequestQueryAll = createAsyncThunk(
  'admin/transactions_requests',
  async () => {
    let query;
    try {
      query = await axios.get(`/api/transaction/request/all`);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const fairsharesQuery = createAsyncThunk('admin/fairshare', async () => {
  let query;
  try {
    query = await axios.get(`/api/fairshare`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const createFairshareQuery = createAsyncThunk(
  'admin/create_fairshare',
  async (payload: { amount: number; comission: number; date: string }) => {
    let query;
    try {
      query = await axios.post(`/api/fairshare`, payload);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const editFairshareQuery = createAsyncThunk(
  'admin/edit_fairshare',
  async (payload: { value: number; id: string; comission: number; date: string }) => {
    let query;
    try {
      query = await axios.patch(`/api/fairshare/${payload.id}`, {
        value: payload.value,
        date: payload.date,
        comission: payload.comission,
      });
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);

export const approveUserQuery = createAsyncThunk('admin/users/aprove', async (id: string) => {
  let query;
  try {
    query = await axios.post(`/api/user/${id}/accept`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const deniedUserQuery = createAsyncThunk('admin/users/decline', async (id: string) => {
  let query;
  try {
    query = await axios.post(`/api/user/${id}/decline`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const approveTransactionQuery = createAsyncThunk(
  'admin/transactions/aprove',
  async (id: string) => {
    let query;
    try {
      query = await axios.post(`/api/transaction/request/${id}/accept`);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const deniedTransactionQuery = createAsyncThunk(
  'admin/transactions/decline',
  async (id: string) => {
    let query;
    try {
      query = await axios.post(`/api/transaction/request/${id}/decline`);
      return query.data;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);

export const createUserQuery = createAsyncThunk(
  'admin/createUser',
  async (payload: createUserPayload) => {
    let query;
    try {
      query = await axios.post(`/api/user/create`, payload);
      return query;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const createTransactionQuery = createAsyncThunk(
  'admin/createTransaction',
  async (payload: createTransactionPayload) => {
    const { user, address, chain, amount, fairshare, comission, type, date } = payload;
    let query;
    try {
      query = await axios.post(`/api/transaction`, {
        user,
        address,
        chain,
        amount: type === 'DEPOSIT' ? amount : -amount,
        fairshare,
        comission,
        type,
        date,
      });
      return query;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const updateTransactionQuery = createAsyncThunk(
  'admin/updateTransaction',
  async ({ id, payload }: { id: string; payload: createTransactionPayload }) => {
    const { user, address, chain, amount, fairshare, comission, type, date } = payload;
    let query;
    try {
      query = await axios.put(`/api/transaction/${id}`, {
        user,
        address,
        chain,
        amount: type === 'DEPOSIT' ? amount : -amount,
        fairshare,
        comission,
        type,
        date,
      });
      return query;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const deleteTransaction = createAsyncThunk('admin/deleteTransaction', async (id: string) => {
  let query;
  try {
    query = await axios.delete(`/api/transaction/${id}`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
export const createRefferalCode = createAsyncThunk(
  'admin/createRefferalCode',
  async (payload: { code: string; user: string }) => {
    let query;
    try {
      query = await axios.post(`/api/referral/create`, payload);
      return query;
    } catch (err) {
      const axiosError: { message: string } = (err as AxiosError).response?.data as {
        code: number;
        message: string;
      };
      return Promise.reject(axiosError.message);
    }
  }
);
export const listRefferalCodeQuery = createAsyncThunk('admin/listRefferalCode', async () => {
  let query;
  try {
    query = await axios.get(`/api/referral`);
    return query.data;
  } catch (err) {
    const axiosError: { message: string } = (err as AxiosError).response?.data as {
      code: number;
      message: string;
    };
    return Promise.reject(axiosError.message);
  }
});
