import React from 'react';
import { useInView } from 'react-intersection-observer';
const Component = () => {
  const { ref, inView } = useInView({
    threshold: 0.6,
  });
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [index, setIndex] = React.useState<number>(0);
  return (
    <div className="container mx-auto w-full grid grid-cols-1 md:grid-cols-3  space-y-20 md:space-y-0">
      <div
        ref={ref}
        className={`flex flex-wrap flex-col xl:flex-row items-center justify-center cursor-pointer   ease-in-out duration-500 ${
          inView ? 'opacity-100' : 'opacity-0'
        }`}
        onMouseOver={() => setIndex(1)}
        onMouseLeave={() => setIndex(0)}>
        <img src={'./assets/financial.png'} className={index === 1 ? 'hoverImg' : ''} />
        <h2 className="text-lg mt-4 xl:mt-0 text-center lg:text-left lg:text-h4 pl-3 font-bold uppercase hover:text-violet">
          Financial Cycles
        </h2>
      </div>
      <div
        ref={ref}
        className={`flex flex-wrap flex-col xl:flex-row items-center justify-center cursor-pointer   ease-in-out duration-500 ${
          inView ? 'opacity-100' : 'opacity-0'
        }`}
        onMouseOver={() => setIndex(2)}
        onMouseLeave={() => setIndex(0)}>
        <img src={'./assets/narrative.png'} className={index === 2 ? 'hoverImg' : ''} />
        <h2 className="text-lg mt-4 xl:mt-0 text-center lg:text-left lg:text-h4 pl-3 font-bold uppercase hover:text-violet">
          Crypto Narrative
        </h2>
      </div>
      <div
        ref={ref}
        className={`flex flex-wrap flex-col xl:flex-row items-center justify-center cursor-pointer   ease-in-out duration-500 ${
          inView ? 'opacity-100' : 'opacity-0'
        }`}
        onMouseOver={() => setIndex(3)}
        onMouseLeave={() => setIndex(0)}>
        <img src={'./assets/market.png'} className={index === 3 ? 'hoverImg' : ''} />
        {/* <MarketIcon fill={index === 3 ? '#CBF429' : '#fff'} /> */}
        <h2 className="text-lg mt-4 xl:mt-0 text-center lg:text-left lg:text-h4 pl-3 font-bold uppercase hover:text-violet">
          Market Making
        </h2>
      </div>
    </div>
  );
};
export default Component;
