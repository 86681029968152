import moment from 'moment';
import React from 'react';
import { ChainTypes } from '../../../data/chains';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createTransactionQuery, usersQuery } from '../../../store/api/admin.api';
import { validateAddress } from '../../../utils/validate';
interface IForm {
  user: string;
  address: string;
  chain: string;
  amount: number;
  fairshare: number;
  comission: number;
  type: string;
  date: string;
}
const Component = () => {
  const dispatch = useAppDispatch();
  const { users, isFetching } = useAppSelector((state) => state.admin);
  const chains = Object.keys(ChainTypes);

  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<IForm>({
    user: '',
    address: '',
    chain: 'TRC20',
    amount: 0,
    comission: 0,
    fairshare: 0,
    type: 'DEPOSIT',
    date: moment(new Date()).format('YYYY-MM-DD'),
  });
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(createTransactionQuery(form));
  };
  React.useEffect(() => {
    dispatch(usersQuery('ACCEPTED'));
  }, []);
  React.useEffect(() => {
    const { user, address, amount, type, date, fairshare } = form;
    if (
      user !== '' &&
      address !== '' &&
      amount !== 0 &&
      fairshare !== 0 &&
      type !== '' &&
      date !== '' &&
      validateAddress(form.address, form.chain)
    )
      setIsValid(true);
    else setIsValid(false);
  }, [form]);
  return (
    <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
      <h3 className="w-full text-center text-2xl my-5">Create transaction</h3>
      <div className="mb-5">
        <div className="mb-5">
          <label className="text-sm">User</label>
          <select className="w-full" onChange={(e) => setForm({ ...form, user: e.target.value })}>
            <option key={null} value={''}>
              Select a user
            </option>
            {users.map((user) => (
              <option key={user._id} value={user._id}>
                {user.firstName} {user.lastName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mb-5">
        <div className="mb-5">
          <label className="text-sm">Type</label>
          <select className="w-full" onChange={(e) => setForm({ ...form, type: e.target.value })}>
            <option key={'DEPOSIT'} value={'DEPOSIT'}>
              Deposit
            </option>
            <option key={'WITHDRAW'} value={'WITHDRAW'}>
              Withdraw
            </option>
            <option key={'PERFORMANCE_FEE'} value={'PERFORMANCE_FEE'}>
              Performance Fee
            </option>
          </select>
        </div>
      </div>
      <div className="mb-5">
        <label className="w-full mb-1 font-semibold">Date</label>
        <input
          type="date"
          className="w-full mb-4"
          placeholder="Fecha"
          step="any"
          onChange={(e) => setForm({ ...form, date: moment(e.target.value).format('YYYY-MM-DD') })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Amount</label>
        <input
          type={'number'}
          onWheel={(event) => event.currentTarget.blur()}
          className="w-full"
          onChange={(e) => setForm({ ...form, amount: +e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Fairshare</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, fairshare: +e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Comission</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, comission: +e.target.value })}
        />
      </div>
      <div className="mb-5">
        <label className="text-sm">Chain</label>
        <select className="w-full" onChange={(e) => setForm({ ...form, chain: e.target.value })}>
          <option key={null} value={''}>
            Select a chain
          </option>
          {chains.map((chain) => (
            <option key={chain} value={chain}>
              {chain}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-5">
        <label className="text-sm">Address</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, address: e.target.value })}
        />
        {form.address !== '' && !validateAddress(form.address, form.chain) && (
          <p className="text-sm text-red-400 mt-2">La address ingresada no es válida</p>
        )}
      </div>

      <div className="w-full flex flex-wrap items-center justify-end">
        <input
          type="submit"
          value="Create"
          className={isValid ? 'bg-violet' : 'bg-gray-400'}
          disabled={!isValid || isFetching}
        />
      </div>
    </form>
  );
};
export default Component;
