import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { createRefferalCode, usersQuery } from '../../../store/api/admin.api';
interface IForm {
  code: string;
  user: string;
}
const Component = () => {
  const { users, isFetching } = useAppSelector((state) => state.admin);
  const dispatch = useAppDispatch();
  const [isValid, setIsValid] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<IForm>({
    code: '',
    user: '',
  });
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(createRefferalCode(form));
  };
  React.useEffect(() => {
    dispatch(usersQuery('all'));
  }, []);
  React.useEffect(() => {
    const { code, user } = form;
    if (code !== '' && user !== '') setIsValid(true);
    else setIsValid(false);
  }, [form]);
  return (
    <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
      <div className="mb-5">
        <label className="text-sm">Code</label>
        <input
          type={'text'}
          className="w-full"
          onChange={(e) => setForm({ ...form, code: e.target.value })}
        />
      </div>

      <div className="mb-5">
        <label className="text-sm">User</label>
        <select className="w-full" onChange={(e) => setForm({ ...form, user: e.target.value })}>
          <option key={''} value="">
            Select one
          </option>
          {users
            .filter((user) => user.type !== 'user')
            .map((user) => (
              <option key={user._id} value={user._id}>
                {`${user.firstName} ${user.lastName}`}
              </option>
            ))}
        </select>
      </div>

      <div className="w-full flex flex-wrap items-center justify-end">
        <input
          type="submit"
          value="Create"
          className={isValid ? 'bg-violet' : 'bg-gray-400'}
          disabled={!isValid || isFetching}
        />
      </div>
    </form>
  );
};
export default Component;
