import { useAppSelector } from '../../../hooks';
import { Header } from '../../organisms';
interface Props {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  handleClickScroll?: Function;
}
const Template = (props: Props) => {
  const { children, handleClickScroll } = props;
  const { token } = useAppSelector((state) => state.auth);

  return (
    <main>
      <Header
        isLogged={token !== null}
        handleClickScroll={handleClickScroll ? handleClickScroll : () => {}}
      />

      <>{children}</>
    </main>
  );
};
export default Template;
