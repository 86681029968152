import React from 'react';
import { useAppSelector } from '../../../hooks';

const Component = () => {
  const [active, setActive] = React.useState<string>('ytd');
  const { ytd, since_inception } = useAppSelector((state) => state.user);
  return (
    <div className="w-full mb-5">
      <div className="bg-darkgray rounded-t-md border border-darkgray">
        <h2 className="font-semibold uppercase text-center py-2 lg:text-xl md:text-lg">
          Total Pnl
        </h2>
      </div>
      <div className="overflow-x">
        <table className="table-auto mt-1 w-full border border-darkgray rounded-b-md">
          <thead className="border-b border-darkgray rounded-b-md">
            <th
              className={`w-1/2 py-2 text-center ${active === 'ytd' && 'bg-darkgray text-lime'}`}
              onClick={() => setActive('ytd')}>
              YTD
            </th>
            <th
              className={`w-1/2 py-2 text-center ${active === 'since' && 'bg-darkgray text-lime'}`}
              onClick={() => setActive('since')}>
              Since inception
            </th>
          </thead>
          <tbody>
            <tr className="text-center">
              <td colSpan={12} className=" py-2 text-lime text-center text-h3 lg:text-h2 font-bold">
                {active === 'ytd'
                  ? ytd
                    ? ytd.toFixed(1)
                    : 0
                  : since_inception
                  ? since_inception.toFixed(1)
                  : 0}{' '}
                %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Component;
