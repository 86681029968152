import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Layout } from '../../components/templates';
import { useAppDispatch } from '../../hooks';
import { loginQuery } from '../../store/api/auth.api';
interface IForm {
  email: string;
  password: string;
}
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form, setForm] = React.useState<IForm>({
    email: '',
    password: '',
  });
  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    await dispatch(loginQuery(form)).then((action) => {
      if (action.meta.requestStatus === 'fulfilled') navigate('/');
    });
  };

  return (
    <Layout>
      <div className="container mx-auto w-full z-10 py-40">
        <h1 className="text-4xl font-bold text-center mb-5">Login</h1>
        <div className="grid lg:grid-cols-1 md:grid-cols-1 gap-5 md:gap-12">
          <div className="w-full">
            <form className="w-full lg:w-1/2 mx-auto" onSubmit={(e) => handleSubmit(e)}>
              <div className="mb-5">
                <label className="text-sm">Email</label>
                <input
                  type={'text'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                />
              </div>
              <div className="mb-5">
                <label className="text-sm">Password</label>
                <input
                  type={'password'}
                  className="w-full"
                  onChange={(e) => setForm({ ...form, password: e.target.value })}
                />
              </div>
              <div className="w-full flex flex-wrap justify-start">
                <Link to="/recover-account" className="text-violet text-md">
                  Forgot password ?
                </Link>
              </div>
              <div className="w-full flex flex-wrap items-center justify-end">
                <Link to="/register" className="pr-4 text-white text-md">
                  SIGN UP
                </Link>
                <input type="submit" value="SIGN IN" className="bg-violet" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default LoginPage;
