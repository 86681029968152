import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { FairshareHistory } from '../../../models/FairshareHistory';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

interface IProps {
  data: FairshareHistory[];
  fairshare: number;
}
const Component = (props: IProps) => {
  const { data } = props;
  const labels = data.map((d) => d.month);
  const formatedData = {
    labels,
    datasets: [
      {
        type: 'bar' as const,
        label: 'NAV',
        backgroundColor: '#9788F0',
        data: data.map((d) => d.value),
        borderWidth: 0,
      },
    ],
  };
  return (
    <Chart
      type="bar"
      data={formatedData}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: '#fff',
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return ` ${context.parsed.y}%`;
              },
            },
          },
        },
        scales: {
          x: {
            border: {
              display: true,
            },
            grid: {
              display: true,
              drawOnChartArea: true,
              drawTicks: true,
            },
          },
          y: {
            border: {
              display: true,
            },
            grid: {
              color: function (context) {
                if (context.tick.value > 0) {
                  return '#212020';
                } else if (context.tick.value < 0) {
                  return '#212020';
                }

                return '#212020';
              },
            },
          },
        },
      }}
    />
  );
};
export default Component;
