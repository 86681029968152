import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

const Component = () => {
  const sections = [
    {
      key: '/admin',
      label: <FontAwesomeIcon icon={faHome} />,
    },
    {
      key: '/admin/users',
      label: 'Users',
    },

    {
      key: '/admin/refferal-codes',
      label: 'Refferal Codes',
    },
    {
      key: '/admin/transactions',
      label: 'Transactions',
    },

    {
      key: '/admin/transactions-requests',
      label: 'Transactions Requests',
    },
    {
      key: '/admin/fairshares',
      label: 'Fairshares History',
    },
  ];
  const location = useLocation();
  return (
    <div className="flex flex-row items-center justify-start space-x-6">
      {sections.map((section) => {
        const isActive = location.pathname === section.key;
        const classActive = `text-lime text-md font-semibold`;
        const defaultClass = 'text-md text-white font-semibold hover:text-violet';
        return (
          <Link
            to={section.key}
            className={isActive ? classActive : defaultClass}
            key={section.key}>
            {section.label}
          </Link>
        );
      })}
    </div>
  );
};
export default Component;
